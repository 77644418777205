import React, { FC } from 'react';
import {Chip, ListItemText, MenuItem} from "@material-ui/core";
import {WorkplaceThreatsTypes} from "./TestedPersonsWorkplaceThreatsSelect.types";
import {getTestedPersonsWorkplaceThreatsLabel} from "./TestedPersonsWorkplaceThreatsSelect.utils";
import TextFieldController, {
  TextFieldControllerProps
} from "../../formItems/textFieldController/TextFieldController.component";
import {useTestedPersonsWorkplaceThreatsSelectStyles} from "./TestedPersonsWorkplaceThreatsSelect.styles";

interface TestedPersonsWorkplaceThreatsSelectProps
  extends TextFieldControllerProps {}

const TestedPersonsWorkplaceThreatsSelect:FC<TestedPersonsWorkplaceThreatsSelectProps> = (props) => {
  const styles = useTestedPersonsWorkplaceThreatsSelectStyles()
  const workplaceThreats: WorkplaceThreatsTypes[] = Object.values(WorkplaceThreatsTypes)

  const displayLabels = (selected: any) => (
    <div className={styles.chips}>
      { selected.map((item: WorkplaceThreatsTypes) => {
          const label = getTestedPersonsWorkplaceThreatsLabel(item)

          return (
            <Chip
              key={label}
              label={label}
              className={styles.chip}
              color="primary"
            />
          )
        })
      }
    </div>
  )

  return (
    <TextFieldController
      select
      SelectProps={{
        multiple: true,
        renderValue: displayLabels
      }}
      {...props}
    >
      {workplaceThreats.map((option) => (
         <MenuItem key={option} value={option}>
           <ListItemText primary={getTestedPersonsWorkplaceThreatsLabel(option)} />
         </MenuItem>
       ))}
    </TextFieldController>
  )
}

export default TestedPersonsWorkplaceThreatsSelect