import React, { FC } from 'react'
import TextFieldController, { TextFieldControllerProps } from '../../../formItems/textFieldController/TextFieldController.component';
import { useQuery } from '@apollo/client';
import { MenuItem } from '@material-ui/core';
import { GENDERS } from './Gender.query';

interface GenderControllerProps
  extends TextFieldControllerProps
{ }

const GenderController:FC<GenderControllerProps> = (props) => {
  const { data, loading } = useQuery<{connection: Array<{genderId: string, name: string}>}>(GENDERS);

  return (
    <TextFieldController
      select
      {...props}
      disabled={props.disabled || loading}
    >
      {data?.connection.map(item => (
        <MenuItem
          key={item.genderId}
          value={item.genderId}
        >
          {item.name}
        </MenuItem>
      ))}
    </TextFieldController>
  )
}

export default GenderController