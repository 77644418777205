import { RootState } from "../store.types"
import { UserDataState } from "./user.types"
import { UserRole } from "../../components/user/userRole/UserRole.types"

export const selectUser: ((state: RootState) => UserDataState) = (state) => {
  return state.user
}

export const selectUserRole: ((state: RootState) => UserRole | null) = (state) => {
  // @ts-ignore
  return state.user ? state.user.userRole : null
}

export const selectCurrentTenantId: ((state: RootState) => string[]) = (state) => {
  // @ts-ignore
  return (state.user && state.user.tenantsList?.length) ? state.user.tenantsList.map(tenant => tenant.tenantId) : []
}
