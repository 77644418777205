export enum WorkplaceThreatsTypes {
  NOISE = "NOISE",
  MICROCLIMATE = "MICROCLIMATE",
  REVELATION = "REVELATION",
  DECISION_MAKING = "DECISION_MAKING",
  CONTACT_WITH_CLIENT = "CONTACT_WITH_CLIENT",
  TIME_PRESSURE = "TIME_PRESSURE",
  IMPROVEMENT_QUALIFICATIONS = "IMPROVEMENT_QUALIFICATIONS",
  FORCED_BODY_POSITION = "FORCED_BODY_POSITION",
  MOVING_PARTS_OF_MACHINES = "MOVING_PARTS_OF_MACHINES",
  LEVEL_DIFFERENCE = "LEVEL_DIFFERENCE",
  MOVING_CARGO = "MOVING_CARGO",
  OTHERS = "OTHERS"
}
