import { gql } from "@apollo/client"

export const OCCUPATION_LIST_QUERY = gql`
    query GetAllOccupations {
      connection: occupationTypes {
        edges {
          node {
            name
            occupationTypeId
          }
        }
      }
    }
`