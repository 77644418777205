import React, { FC } from "react"
import { Box } from "@material-ui/core"

const HomePage: FC = () => {

  return (
    <Box>
      <p>Nima</p>
    </Box>
  )
}

export default HomePage