import React, {FC, useEffect, useState} from 'react'
import TextFieldController, {
  TextFieldControllerProps
} from "../../formItems/textFieldController/TextFieldController.component";
import {Box, MenuItem, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {UserSelectItem, UserSelectQueryItem} from "./UserSelect.types";
import {USER_SELECT_LIST_QUERY} from "./UserSelect.query";
import {UserRole} from "../userRole/UserRole.types";

interface UserSelectProps
  extends TextFieldControllerProps
{}

const UserSelect: FC<UserSelectProps> = ({disabled,...props}) => {
  const {data, loading} = useQuery<{connection: {edges: Array<{ node: UserSelectQueryItem}>}}>(USER_SELECT_LIST_QUERY, {
    variables: {
      filter: {
        userRole: {equalTo: UserRole.GUARDIAN},
      }
    }
  });
  const [options, setOptions] = useState<UserSelectItem[]>([])

  useEffect(() => {
    if(data) {
      setOptions(data.connection.edges.map(edge => ({
        userId: edge.node.userId,
        fullName: `${edge.node.name} ${edge.node.surname}`
      })))
    }
  },[loading])

  return(
    <TextFieldController
      select
      {...props}
      disabled={disabled || loading}
    >
      { options.length
        ? (
          options.map((option: UserSelectItem) => (
            <MenuItem
              key={option.userId}
              value={option.userId}
            >
              {option.fullName}
            </MenuItem>
          ))
        )
        : (
          <Box m={2}>
            <Typography variant={"body2"}>
              <i>Brak użytkowników</i>
            </Typography>
          </Box>
        )}
    </TextFieldController>
  )
}

export default UserSelect