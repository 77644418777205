import React, { FC } from 'react'
import { EDUCATION } from './Education.query';
import { useQuery } from '@apollo/client';
import TextFieldController, { TextFieldControllerProps } from '../../../formItems/textFieldController/TextFieldController.component';
import { MenuItem } from '@material-ui/core';

interface EducationControllerProps
  extends TextFieldControllerProps
{ }

type EducationItem = {
  educationId: string,
  name: string
}

const EducationController: FC<EducationControllerProps> = (props) => {
  const { data, loading } = useQuery<{connection: Array<EducationItem>}>(EDUCATION);

  const educationDataItems = data?.connection

  const educationItems = [
    "Brak",
    "Podstawowe",
    "Gimnazjalne",
    "Ponadgimnazjalne",
    "Ogólnokształcące",
    "Techniczne",
    "Zasadnicze zawodowe",
    "Pomaturalne",
    "Wyższe I stopień",
    "Wyższe II stopień",
    "Wyższe III stopień",
  ].reduce((options: EducationItem[], currentItem) => {
    const educationDataItem = educationDataItems?.find(item => item.name === currentItem)
    return educationDataItem
      ? [...options, educationDataItem]
      : options
  }, [])

  return (
    <TextFieldController
      select
      {...props}
      disabled={props.disabled || loading}
    >
      { educationItems.map(item => (
        <MenuItem
          key={item.educationId}
          value={item.educationId}
        >
          {item.name}
        </MenuItem>
      ))}
    </TextFieldController>
  )
}

export default EducationController
