import { useQuery } from '@apollo/client';
import React, { FC } from 'react'
import { WORKTYPES } from './WorkType.query';
import TextFieldController, { TextFieldControllerProps } from '../../../formItems/textFieldController/TextFieldController.component';
import { MenuItem } from '@material-ui/core';

interface WorkTypeControllerProps
  extends TextFieldControllerProps
{ }

const WorkTypeController:FC<WorkTypeControllerProps> = (props) => {
  const { data, loading } = useQuery<{connection: Array<{workTypeId: string, name: string}>}>(WORKTYPES);

  return (
    <TextFieldController
      select
      {...props}
      disabled={props.disabled || loading}
    >
      {data?.connection.map(item => (
        <MenuItem
          key={item.workTypeId}
          value={item.workTypeId}
        >
          {item.name}
        </MenuItem>
      ))}
    </TextFieldController>
  )
}

export default WorkTypeController