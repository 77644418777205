import { DocumentNode } from "graphql";
import { useEffect, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client"
import { TablePaginationProps } from "@material-ui/core"

export interface RelayConnection<T> {
  totalCount: number;
  edges: Array<{node: T}>
}

interface Pagination {
  page: number;
  rowsPerPage: number;
}

interface Options<Variables> {
  variables?: Variables,
  pagination?: [Pagination, (pagination: Pagination) => void],
}

const useRelayConnection = <
  Item,
  Variables extends {input?: Record<string, any>} = Record<string, any>,
  Data extends {connection: RelayConnection<Item>} = {connection: RelayConnection<Item>},
>(
  query: DocumentNode,
  options: Options<Variables> = {}
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [nextPagination, setNextPagination] = options.pagination || useState<Pagination>(initialState);
  const [currentPagination, setCurrentPagination] = useState<Pagination>(initialState);

  const result = useQuery<Data>(query, {
    pollInterval: 60000, // 1 min silent refresh
    fetchPolicy: "no-cache",
    variables: composeVariables(nextPagination)(options.variables),
    onCompleted: () => {
      setCurrentPagination(nextPagination);
    },
  });

  const {data, networkStatus} = result;

  const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;

  const connection = data ? data.connection : voidConnection;
  const numberOfPages = Math.ceil(connection.totalCount / currentPagination.rowsPerPage);

  useEffect(() => {
    let {page, rowsPerPage} = nextPagination;

    if (networkStatus === NetworkStatus.ready) {
      page = Math.min(numberOfPages, page);
    }
    if (!pageSizeOptions.includes(rowsPerPage.toString())) {
      rowsPerPage = defaultPageSize;
    }

    page = Math.max(1, page);

    if (page !== nextPagination.page || rowsPerPage !== nextPagination.rowsPerPage) {
      setNextPagination({page, rowsPerPage});
    }
  }, [numberOfPages, nextPagination, currentPagination, setNextPagination, networkStatus]);

  const pagination: TablePaginationProps = {
    page: currentPagination.page,
    rowsPerPage: currentPagination.rowsPerPage,
    count: connection.totalCount,
    rowsPerPageOptions: pageSizeOptions.map((str: string) => Number(str)),
    onRowsPerPageChange: (event) => {
      setNextPagination({
        page: 1,
        rowsPerPage: parseInt(event.target.value, 10),
      });
    },
    onPageChange: (event, newPage: number) => {
      setNextPagination({
        ...currentPagination,
        page: newPage
      });
    }
  };

  const skipped = currentPagination.rowsPerPage * (currentPagination.page - 1);
  const extraEdges = Math.max(0, connection.edges.length + skipped - connection.totalCount);

  const nodes = connection.edges
    .slice(extraEdges)
    .map((edge) => edge.node);

  const tableProps: any = {
    pagination,
    dataSource: nodes,
    loading,
  };

  return {
    tableProps,
    pagination,
    skipped,
    nodes,
    ...result
  };
};

const defaultPageSize = 10;

const composeVariables = <V extends {input?: Record<string, any>}>(
  pagination: Pagination
) => (variables?: V) => ({
  ...variables,
  ...(variables ? variables.input : {}),
  ...paginationVariables(pagination),
});

const paginationVariables = ({page, rowsPerPage}: Pagination) => ({
  first: rowsPerPage,
  offset: rowsPerPage * (page - 1)
});

const voidConnection: RelayConnection<never> = {
  totalCount: 0,
  edges: [],
};

const initialState: Pagination = {
  page: 1,
  rowsPerPage: defaultPageSize,
};

const pageSizeOptions: string[] = ['10', '20', '30', '40'];

export default useRelayConnection;
