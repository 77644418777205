import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useProjectDataLogosStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
      justifyContent : "center",
      maxWidth: "100%",
      width: "100%",
      marginBottom: "4px",
    },
    imgEu: {
      height: "50px",
      display: "block",
      margin: "auto",
    },
    img: {
      maxWidth: "200px",
      display: "block",
      margin: "0 20px",
      maxHeight: "70px",
      objectFit: "contain"
    }
  }),
)