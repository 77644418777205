import { gql } from "@apollo/client"

export const TENANTS_LIST_QUERY = gql`
    query GetAllTenants($filter: TenantFilter) {
        connection: tenants( filter: $filter) {
            edges {
                node {
                    tenantId
                    name
                }
            }
        }
    }
`;