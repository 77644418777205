import { gql } from "@apollo/client"

export const AGE_CATEGORIES = gql`
    query GetAllCategories {
        connection: ageCategories {
            edges {
                node {
                    ageCategoryId
                    name
                }
            }
        }
    }
`;