import * as React from 'react';
import ReactDOM from 'react-dom';
import store, {history} from "./store"
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from "use-query-params";
import App from './App';
import client from "./client"
import { Provider } from "react-redux"
import { CssBaseline, MuiThemeProvider } from "@material-ui/core"
import theme from "./themes/theme"
import { Route } from "react-router";
import "./assets/styles/index.css"

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ConnectedRouter history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </MuiThemeProvider>
        </QueryParamProvider>
      </ConnectedRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);
