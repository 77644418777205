import {UserRole} from "./UserRole.types"
import {RoutePath} from "../../../routes/Routes.types"

const getHomeRouteForRole = (role: UserRole) => {
  switch(role) {
    case UserRole.EXAMINED_PERSON: return RoutePath.HOMEPAGE;
    case UserRole.GUARDIAN: return RoutePath.TESTED_PERSONS;
    case UserRole.ADMIN: return RoutePath.USERS
    default: return RoutePath.ADMIN_HOMEPAGE
  }
}

const getUserRoleLabel = (role?: UserRole) => {
  switch(role) {
    case UserRole.ADMIN: return "Administrator";
    case UserRole.GUARDIAN: return "Opiekun";
    case UserRole.EXAMINED_PERSON: return "Osoba badana";
    default: return role
  }
}

const getUserRoleChipColor = (role?: UserRole) => {
  switch(role) {
    case UserRole.ADMIN: return "primary";
    case UserRole.GUARDIAN: return "secondary";
    case UserRole.EXAMINED_PERSON: return "primary";
    default: return "default"
  }
}

export {
  getHomeRouteForRole,
  getUserRoleLabel,
  getUserRoleChipColor,
}