import React, { FC } from 'react'
import TextFieldController, { TextFieldControllerProps } from '../../../formItems/textFieldController/TextFieldController.component';
import { MenuItem } from '@material-ui/core';

interface MotivationControllerProps
  extends TextFieldControllerProps
{ }

const MotivationController:FC<MotivationControllerProps> = (props) => {
  const options = [1,2,3,4,5]
  return (
    <TextFieldController
      select
      {...props}
      disabled={props.disabled}
    >
      {options.map(item => (
        <MenuItem
          key={item}
          value={item}
        >
          {item}
        </MenuItem>
      ))}
  </TextFieldController>
  )
}

export default MotivationController