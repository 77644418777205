import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

export const usePageFooterStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.common.white,
    padding: theme.spacing(2, 5),
    textAlign: "right",
    display: "flex",
    justifyContent: "space-between",
  },
}))
