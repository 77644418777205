import { RoutePath } from "../Routes.types"
import { RouteListItem } from "./RouteList.types"
import HomePage from "../../pages/HomePage"
import { UserRole } from "../../components/user/userRole/UserRole.types"

const testedPersonRoutes: RouteListItem[] = [
  {
    path: RoutePath.HOMEPAGE,
    component: HomePage,
    title: "Strona główna",
    permission: [UserRole.EXAMINED_PERSON],
  },
]

export default testedPersonRoutes
