import React, { FC } from "react"
import { MenuItem } from "@material-ui/core"
import { UserRole } from "./UserRole.types"
import { getUserRoleLabel } from "./UserRole.utils"
import TextFieldController, {
  TextFieldControllerProps
} from "../../formItems/textFieldController/TextFieldController.component"
import { useSelector } from "react-redux"
import { selectUserRole } from "../../../store/user/user.selectors"

interface UserRoleSelectProps
  extends TextFieldControllerProps
{}

const UserRoleSelect: FC<UserRoleSelectProps> = (props) => {
  const userRole = useSelector(selectUserRole)

  return (
    <TextFieldController
      {...props}
      select
    >
      { Object.values(UserRole)
        .filter(option =>
          (userRole === UserRole.ADMIN && option !== UserRole.EXAMINED_PERSON)
          || (userRole === UserRole.GUARDIAN && option !== UserRole.ADMIN)
          || (userRole === UserRole.EXAMINED_PERSON && option === UserRole.EXAMINED_PERSON)
        )
        .map(value => (
          <MenuItem
            key={value}
            value={value}
          >
            { getUserRoleLabel(value) }
          </MenuItem>
        ))
      }
    </TextFieldController>
  )
}

export default UserRoleSelect
