import { gql } from "@apollo/client"

export const TENANTS_LIST_QUERY = gql`
    query GetTenantsList(
        $first: Int,
        $offset: Int,
        $filter: TenantFilter,
        $orderBy: [TenantsOrderBy!]
    ) {
        connection: tenants(
          first: $first,
          offset: $offset,
          orderBy: $orderBy,
          filter: $filter
        ) {
            totalCount
            edges {
                node {
                    tenantId
                    name
                    archived
                    createdAt
                    gurdiansCount: membersCount(userRole: ROLE_GUARDIAN)
                    examinedPersonsCount: membersCount(userRole: ROLE_EXAMINED_PERSON)
                }
            }
        }
    }
`;