import React, {FC} from "react"
import { Box, Typography } from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useMutation } from "@apollo/client";
import { ARCHIVE_USER } from "./ArchiveUser.mutation"
import { UserItemNode } from "../../../store/user/user.types"

interface ArchiveUserPopupProps {
  user: UserItemNode;
  open: boolean;
  onClose(): void;
  refetch(): void;
}

const ArchiveUserPopup: FC<ArchiveUserPopupProps> = ({user, open, refetch, onClose}) => {

  const [archiveUser, {loading}] = useMutation(ARCHIVE_USER)

  const { userId, name, surname } = user

  const handleSubmit = async () => {
    try {
      await archiveUser({
        variables: {
          input: {
            userId: userId,
          }
        }
      })

      await refetch()
      onClose()
    } catch (exception) {
      // processResponseException(form, exception);
    }
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Archiwizuj użytkownika"
      closeButtonText={"Nie, zamknij"}
      actionButtonText={"Tak, potwierdź"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <Typography>
          Czy na pewno chcesz zarchiwizować użytkownika <b>{ name } { surname }</b>?
        </Typography>
        <Typography>
          <b>Uwaga!</b> Operacja archiwizacji jest nieodwracalna.
        </Typography>
      </Box>
    </Popup>
  )
}

export default ArchiveUserPopup
