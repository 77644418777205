import {makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useUserMenuStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2)
  },
  userText: {
    margin: theme.spacing(0, 2)
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.dark,
  },
  listRoot: {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
  },
  activeItem: {
    backgroundColor: theme.palette.secondary.light,
  }
}))
