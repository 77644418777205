export const palette = {
  primary: {
    main: "#416b64",
    light: "#397067",
    dark: "#375b55"
  },
  secondary: {
    main: "#59595d",
    dark: "#242428",
    light: "#454548",
    contrastText: "#e0e0e0"
  },
  background: {
    default: "#2e5a53",
    paper: "#F0F4F8",
  },
  error: {
    main: "#BA2525",
    light: "#FFCCCC",
  },
  success: {
    main: "#379683",
    dark: "#80b918"
  },
  action: {
    disabledBackground: "rgba(130, 130, 130, 0.95)",
    disabled: "#D3D3D3",
  }
}