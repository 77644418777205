import React, {FC} from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTableWithPagination } from "./TableWithPagination.styles"
import {TablePaginationProps, Typography, Box, TableCell, TableBody} from "@material-ui/core"
import TablePaginationActions from "./pagination/TablePaginationActions.component"

interface TableWithPaginationProps {
  pagination: TablePaginationProps;
  loading: boolean;
}

const TableWithPagination: FC<TableWithPaginationProps> = ({pagination, loading, children}) => {
  const classes = useTableWithPagination()

  const {
    rowsPerPage,
    page,
    count,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange
  } = pagination

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        { children }

        { (count === 0 && !loading) && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={1000}>
                <Box mt={2}>
                  <Typography variant={"body2"}>
                    <i>Brak wyników</i>
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              ActionsComponent={(subProps) => <TablePaginationActions {...subProps} {...pagination}/>}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default TableWithPagination