import store from "./store"

const initHeaders: Record<string, string> = {};

const clientFetch: WindowOrWorkerGlobalScope["fetch"] = async (uri, options?: RequestInit) => {
  const { session } = store.getState()

  const headers = {
    ...(options ? options.headers : {}) as Record<string, string>,
    ...initHeaders,
  };

  if (session && session.sessionState && session.sessionState.sessionToken) {
    headers['Authorization'] = `Bearer ${session.sessionState.sessionToken}`;
  }

  return await fetch(uri, {
    ...options,
    headers,
  })
}

export const setToken = (token: string|null) => {
  const headerName = "Authorization";
  if (!token) delete initHeaders[headerName];
  else initHeaders[headerName] =  `Bearer ${token}`;
}

export default clientFetch;
