import React, { FC, useState } from "react"
import {Button, ButtonGroup, Tooltip} from "@material-ui/core"
import { useUsersListActionsStyles } from "./UsersListActions.styles"
import EditUserPopup from "../editUserPopup/EditUser.component"
import { UserItemNode } from "../../../store/user/user.types"
import ArchiveUserPopup from "../archiveUserPopup/ArchiveUser.component"
import {EditOutlined, ArchiveOutlined} from '@material-ui/icons'

interface UsersListActionsProps {
  user: UserItemNode;
  refetch(): void;
}

const UsersListActions: FC<UsersListActionsProps> = ({ user, refetch }) => {
  const classes = useUsersListActionsStyles()

  const [isOpenEditUserPopup, setIsOpenEditUserPopup] = useState<boolean>(false)
  const [isOpenArchiveUserPopup, setIsOpenArchiveUserPopup] = useState<boolean>(false)

  return (
        <ButtonGroup>
          <Tooltip title={'Edytuj dane użytkownika'}>
          <Button
            className={classes.button}
            onClick={() => setIsOpenEditUserPopup(true)}
          >
            <EditOutlined className={classes.icon}/>
          </Button>
          </Tooltip>

          <Tooltip title={'Archiwizuj użytkownika'}>
            <Button
              className={classes.button}
              onClick={() => setIsOpenArchiveUserPopup(true)}
              >
              <ArchiveOutlined className={classes.icon}/>
            </Button>
          </Tooltip>

          <ArchiveUserPopup
            user={user}
            open={isOpenArchiveUserPopup}
            refetch={refetch}
            onClose={() => setIsOpenArchiveUserPopup(false)}
          />

          <EditUserPopup
            user={user}
            open={isOpenEditUserPopup}
            refetch={refetch}
            onClose={() => setIsOpenEditUserPopup(false)}
          />
        </ButtonGroup>
  )
}

export default UsersListActions
