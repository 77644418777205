import React, {FC, useEffect} from "react"
import {Grid} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import {FormProvider, useForm} from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import {useMutation} from "@apollo/client";
import {EDIT_USER} from "./EditUser.mutation"
import {ExaminedPersonUserItemNode, UserItemNode} from "../../../store/user/user.types"
import {useDispatch} from "react-redux"
import {setUserData} from "../../../store/user/user.slice"
import TestedPersonOccupationSelect
  from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.component";
import {UserRole} from "../userRole/UserRole.types";
import TestedPersonsWorkplaceThreatsSelect
  from "../../testedPersons/testedPersonsWorkplaceThreatsSelect/TestedPersonsWorkplaceThreatsSelect.component";
import {yupResolver} from "@hookform/resolvers/yup";
import {editUserValidationSchema} from "./EditUser.validation";
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component";
import AgeCategoryController from '../addUserPopup/ageCategoryController/AgeCategoryController';
import GenderController from '../addUserPopup/genderController/GenderController.component';
import MotivationController from '../addUserPopup/motivationController/MotivationController.component';
import SeniorityController from '../addUserPopup/seniorityController/SeniorityController.component';
import EducationController from '../addUserPopup/educationController/EducationController.component';
import WorkTypeController from '../addUserPopup/workTypeController/WorkTypeController.component';
import {GridSize} from "@material-ui/core/Grid/Grid";

interface EditUserPopupProps {
  user: UserItemNode| ExaminedPersonUserItemNode | null;
  open: boolean;
  onClose(): void;
  refetch?(): void;
}

const EditUserPopup: FC<EditUserPopupProps> = ({user, open, refetch, onClose}) => {
  const [editUser, {loading}] = useMutation(EDIT_USER)
  const dispatch = useDispatch()

  const isEditCurrentUser = !refetch

  const isExaminedPerson = user?.userRole === UserRole.EXAMINED_PERSON

  const gridSize = {
    xs: 12 as GridSize,
    sm: isExaminedPerson ? 6 : 12 as GridSize,
    lg: isExaminedPerson ? 4 : 12 as GridSize,
  }

  const form = useForm({
    mode: "all",
    shouldUnregister: true,
    resolver: yupResolver(editUserValidationSchema),
    defaultValues: {
      name: user?.name,
      surname: user?.surname,
      userRole: user?.userRole,
      tenants: user?.tenantsList?.map(tenant => tenant.tenantId) || [],
      occupation: (user as ExaminedPersonUserItemNode)?.occupationType?.occupationTypeId,
      workplaceThreats: (user as ExaminedPersonUserItemNode)?.workplaceThreats,
      birthYear: (user as ExaminedPersonUserItemNode)?.birthYear,
      age: (user as ExaminedPersonUserItemNode)?.age ?? "",
      ageCategoryId: (user as ExaminedPersonUserItemNode)?.ageCategoryId ?? "",
      genderId: (user as ExaminedPersonUserItemNode)?.genderId ?? "",
      motivation: (user as ExaminedPersonUserItemNode)?.motivation ?? "",
      seniorityId: (user as ExaminedPersonUserItemNode)?.seniorityId ?? "",
      educationId: (user as ExaminedPersonUserItemNode)?.educationId ?? "",
      weigh: (user as ExaminedPersonUserItemNode)?.weigh ?? "",
      height: (user as ExaminedPersonUserItemNode)?.height ?? "",
      bmi: (user as ExaminedPersonUserItemNode)?.bmi ?? "",
      workTypeId: (user as ExaminedPersonUserItemNode)?.workTypeId ?? "",
    }
  })

  useEffect(() => {
    if (open) {
      form.setValue("name", user?.name)
      form.setValue("surname", user?.surname)
      form.setValue("userRole", user?.userRole)
      form.setValue("tenants", user?.tenantsList?.map(tenant => tenant.tenantId) || [])

      if (user?.userRole === UserRole.EXAMINED_PERSON) {
        form.setValue("occupation", (user as ExaminedPersonUserItemNode)?.occupationType?.occupationTypeId)
        form.setValue("workplaceThreats", (user as ExaminedPersonUserItemNode)?.workplaceThreats)
        form.setValue("birthYear", (user as ExaminedPersonUserItemNode)?.birthYear ?? "")
        form.setValue("age", (user as ExaminedPersonUserItemNode)?.age ?? "")
        form.setValue("ageCategoryId", (user as ExaminedPersonUserItemNode)?.ageCategoryId ?? "")
        form.setValue("genderId", (user as ExaminedPersonUserItemNode)?.genderId ?? "")
        form.setValue("motivation", (user as ExaminedPersonUserItemNode)?.motivation ?? "")
        form.setValue("seniorityId", (user as ExaminedPersonUserItemNode)?.seniorityId ?? "")
        form.setValue("educationId", (user as ExaminedPersonUserItemNode)?.educationId ?? "")
        form.setValue("weigh", (user as ExaminedPersonUserItemNode)?.weigh ?? "")
        form.setValue("height", (user as ExaminedPersonUserItemNode)?.height ?? "")
        form.setValue("bmi", (user as ExaminedPersonUserItemNode)?.bmi ?? "")
        form.setValue("workTypeId", (user as ExaminedPersonUserItemNode)?.workTypeId ?? "")
      }
    }
  }, [open])

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      const payloadData = {
        userId: user?.userId,
        name: values.name,
        surname: values.surname,
        userRole: values.userRole,
        email: user?.email || "",
        occupationTypeId: values.occupation,
        workplaceThreats: values.workplaceThreats,
        tenants: values.tenants,
        birthYear: Number(values.birthYear),
        age: Number(values.age),
        ageCategoryId: values.ageCategoryId,
        genderId: values.genderId,
        motivation: Number(values.motivation),
        seniorityId: values.seniorityId,
        educationId: values.educationId,
        weigh: Number(values.weigh),
        height: Number(values.height),
        bmi: Number(values.bmi.replaceAll(",", ".")),
        workTypeId: values.workTypeId,
      }

      await editUser({
        variables: {
          input: payloadData
        }
      })

      if (refetch) {
        await refetch()
      } else {
        dispatch(setUserData({
          ...payloadData,
          tenantsList: values.tenants.map(t => ({ tenantId: t, name: "" }))
        }))
      }
      handleClose()
    } catch (e) {
      console.error(e)
    }
  })

  if (!user) return null

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title={isEditCurrentUser ? "Moje dane" : isExaminedPerson ? "Edytuj osobę badaną" : "Edytuj użytkownika"}
      actionButtonText={"Zapisz"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
      desktopWidth={isExaminedPerson ? "1200px" : "800px"}
    >
      <FormProvider {...form} >
        <form
          autoComplete="off"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item {...gridSize}>
              <TextFieldController
                name="name"
                label={'Imię'}
                isRequired
                mb
                disabled={loading}
              />
            </Grid>

            <Grid item {...gridSize}>
              <TextFieldController
                name="surname"
                label={'Nazwisko'}
                isRequired
                mb
                disabled={loading}
              />
            </Grid>

            <Grid item {...gridSize}>
              <TenantsSelect
                name="tenants"
                label={'Podmiot'}
                isRequired
                mb
                disabled={loading}
              />
            </Grid>

            {isExaminedPerson && (
              <>
                <Grid item {...gridSize}>
                  <TestedPersonOccupationSelect
                    name={'occupation'}
                    label={'Typ zawodu'}
                    mb
                    isRequired
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <TestedPersonsWorkplaceThreatsSelect
                    name={'workplaceThreats'}
                    label={"Zagrożenia na stanowisku pracy"}
                    isRequired
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <TextFieldController
                    name="birthYear"
                    label={'Rok urodzenia'}
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <TextFieldController
                    name="age"
                    label={'Wiek'}
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <AgeCategoryController
                    name='ageCategoryId'
                    label='Kategoria wiekowa'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <GenderController
                    name='genderId'
                    label='Płeć'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <MotivationController
                    name='motivation'
                    label='Poziom motywacji do uczestnictwa'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <SeniorityController
                    name='seniorityId'
                    label='Staż pracy w firmie'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <EducationController
                    name='educationId'
                    label='Wykształcenie'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <TextFieldController
                    name='weigh'
                    label='Masa ciała (kg)'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <TextFieldController
                    name='height'
                    label='Wysokość (cm)'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <TextFieldController
                    name='bmi'
                    label='BMI'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item {...gridSize}>
                  <WorkTypeController
                    name='workTypeId'
                    label='Rodzaj pracy'
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Popup>
  )
}

export default EditUserPopup
