import React, {FC} from "react"
import { Container, Box, Divider, Toolbar, } from "@material-ui/core"
import useLayoutStyles from "./Layout.styles"
import PageFooter from "../components/pageFooter/PageFooter.component"
import MenuItemsComponent from "../components/menu/mainMenu/MenuItems.component"
import UserMenu from "../components/menu/userMenu/UserMenu.component"
import SessionManager from "../components/common/sessionManager/SessionManager.component"
import clsx from "clsx";

interface DefaultLayoutProps {}

const AdminLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const classes = useLayoutStyles({})

  return (
    <div className={classes.adminRoot}>
      <Box className={clsx(classes.appBar, "print-hidden")}>
        <Toolbar className={classes.topToolbar}>
          <SessionManager/>

          <UserMenu/>
        </Toolbar>
      </Box>

      <Box className={clsx(classes.drawerPaper, "print-hidden")}>
        <div className={classes.toolbarHeader}>
          Menu
        </div>

        <Divider className={classes.divider} />

        <Box>
          <MenuItemsComponent/>
        </Box>
      </Box>

      <Box
        component="main"
        className={clsx(classes.adminContent, "print-page print-100-width")}
      >
        <div className={clsx(classes.appBarSpacer, "print-hidden")} />

        <Container
          maxWidth="lg"
          className={classes.adminContentContainer}
        >
          <Box mb="auto">
            { children }
          </Box>
        </Container>

        <PageFooter/>
      </Box>
    </div>
  )
}

export default AdminLayout
