import { gql } from "@apollo/client"

export const ADD_USER = gql`
    mutation registerUser($createUser: CreateUserInput!, $requestPasswordReset: RequestPasswordResetInput!) {
      createUser(input: $createUser){
        clientMutationId
      }
      requestPasswordReset(input: $requestPasswordReset) {
        clientMutationId
      }
    }
`