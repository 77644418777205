import React, {FC} from "react"
import {Box} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useForm, FormProvider } from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import { useMutation } from "@apollo/client";
import { EDIT_TENANT } from "./EditTenant.mutation"
import { yupResolver } from "@hookform/resolvers/yup"
import { editTenantValidationSchema } from "./EditTenant.validation"

interface EditTenantPopupProps {
  tenantId: string;
  name: string;
  open: boolean;
  onClose(): void;
  refetch(): void;
}

const EditTenantPopup: FC<EditTenantPopupProps> = ({tenantId, name, open, refetch, onClose}) => {
  const [editTenant, {loading}] = useMutation(EDIT_TENANT)

  const form = useForm({
    mode: "all",
    resolver: yupResolver(editTenantValidationSchema),
    defaultValues: {
      name,
    }
  })

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await editTenant({
        variables: {
          input: {
            tenantId,
            patch: values
          }
        }
      })

      await refetch()
      handleClose()
    } catch (exception) {
      // processResponseException(form, exception);
    }
  })

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title="Zapisz"
      actionButtonText={"Zapisz"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <TextFieldController
              name="name"
              label={'Nazwa'}
              isRequired
              trimStart
              disabled={loading}
            />
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default EditTenantPopup
