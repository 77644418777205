import {WorkplaceThreatsTypes} from "./TestedPersonsWorkplaceThreatsSelect.types";

export const getTestedPersonsWorkplaceThreatsLabel = (threat: WorkplaceThreatsTypes) => {
  switch (threat){
    case WorkplaceThreatsTypes.CONTACT_WITH_CLIENT: return "Obciążenie psychiczne związane z kontaktem z klientem"
    case WorkplaceThreatsTypes.DECISION_MAKING: return "Obciążenie psychiczne związane z koniecznością podejmowania decyzji"
    case WorkplaceThreatsTypes.FORCED_BODY_POSITION: return "Przeciążenie układu ruchu - utrzymywanie wymuszonych pozycji ciała"
    case WorkplaceThreatsTypes.MICROCLIMATE: return "Mikroklimat zimny/gorący/zmienny"
    case WorkplaceThreatsTypes.MOVING_PARTS_OF_MACHINES: return "Zagrożenie wypadkowe związane z pracą przy ruchomych elementach maszyn"
    case WorkplaceThreatsTypes.TIME_PRESSURE: return "Obciążenie psychiczne związane z pracą pod presją czasu, w narzuconym tempie pracy"
    case WorkplaceThreatsTypes.IMPROVEMENT_QUALIFICATIONS: return "Obciążenie związane z koniecznością ciągłego podnoszenia kwalifikacji i korzystania z najnowszych technologii"
    case WorkplaceThreatsTypes.NOISE: return "Hałas"
    case WorkplaceThreatsTypes.OTHERS: return "Inne"
    case WorkplaceThreatsTypes.REVELATION: return "Narażenia na olśnienie, związane ze wzrokiem"
    case WorkplaceThreatsTypes.LEVEL_DIFFERENCE: return "Różnica poziomów, praca na podwyższeniu, na wysokości"
    case WorkplaceThreatsTypes.MOVING_CARGO: return "Przeciążenie układu ruchu (WE>1500kcal dla M i 1100 dla K), przenoszenie ładunków"
  }
}