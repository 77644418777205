import React, { FC, useState, useEffect } from "react"
import { Box, Button, InputAdornment, Typography } from "@material-ui/core"
import TextFieldController from "../../../components/formItems/textFieldController/TextFieldController.component"
import { useForm, FormProvider } from "react-hook-form"
import { useResetPaswordPageStyles } from "./ResetPasswordPage.styles"
import AuthPageCard from "../../../components/authPageCard/AuthPageCard.component"
import { RouteComponentProps, useHistory, useParams } from "react-router"
import { yupResolver } from "@hookform/resolvers/yup"
import { resetPasswordValidationSchema } from "./ResetPasswordPage.validation"
import { useMutation } from "@apollo/client"
import { RESET_PASSWORD } from "./ResetPasswortPage.mutation"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { RoutePath } from "../../../routes/Routes.types"
import GlobalFormErrorMessage
  from "../../../components/formItems/errors/globalFormErrorMessage/GlobalFormErrorMessage.component";
import {useAppDispatch} from "../../../hooks/storeHooks";
import { clearSession } from "../../../store/session/session.slice";

const SET_NEW_PASSWORD_QUERY_PARAM = "new" // userRegistered.html.pug, userRegistered.txt.pug

interface ResetPasswordPageProps {
  token: string;
  newPassword?: string;
}

const ResetPasswordPage: FC<RouteComponentProps<ResetPasswordPageProps>> = () => {
  const {token, newPassword} = useParams<ResetPasswordPageProps>()
  const {push} = useHistory()
  const [resetPassword, {loading}] = useMutation(RESET_PASSWORD)
  const dispatch = useAppDispatch()

  const isSetNewPasswordPage = newPassword === SET_NEW_PASSWORD_QUERY_PARAM
  if (newPassword && newPassword !== SET_NEW_PASSWORD_QUERY_PARAM) {
    push(RoutePath.ERROR_404)
  }

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const classes = useResetPaswordPageStyles()

  const form = useForm({
    mode: "all",
    resolver: yupResolver(resetPasswordValidationSchema),
    defaultValues: {
      password: "",
      passwordRepeat: "",
    }
  })

  useEffect(() => {
    // clear session on enter reset-password page to avoid permission-denied api error-response
    dispatch(clearSession())
  }, [])

  const handleSubmit = form.handleSubmit(async (values) => {
    setError("")

    try {
      await resetPassword({
        variables: {
          input: {
            token,
            password: values.password
          }
        }
      })

      form.reset()
      push('/login')
    } catch (e) {
      console.error(e)
      if ((e as Error).message === "Invalid password reset token") {
        setError("Token do resetu hasła został już wykorzystany lub wygasł")
      }
      if ((e as Error).message === "Password has been already used") {
        setError("Podane hasło było już użyte")
      }
    }
  })

  return (
    <AuthPageCard title={isSetNewPasswordPage ? "Ustawienie hasła" : "Reset hasła"}>
      <>
        <Box mb={2} display="inline-block">
          <Typography color="textSecondary">
            { isSetNewPasswordPage
              ? "Ustaw hasło. Po jego zapisaniu zostaniesz przekierowany na stronę logowania."
              : "Wprowadź nowe hasło. Po jego zapisaniu zostaniesz przekierowany na stronę logowania."
            }
          </Typography>
        </Box>

        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            { error &&
              <GlobalFormErrorMessage message={error}/>
            }

            <TextFieldController
              name="password"
              label={'Nowe hasło'}
              isRequired
              mb
              disabled={loading}
              className={classes.input}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={loading}
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                    >
                      { showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/> }
                    </Button>
                  </InputAdornment>
                )
              }}
            />

            <TextFieldController
              name="passwordRepeat"
              label={'Powtórz hasło'}
              isRequired
              mb
              disabled={loading}
              className={classes.input}
              type={showRepeatPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={loading}
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                      type="button"
                    >
                      { showRepeatPassword ? <VisibilityOffIcon/> : <VisibilityIcon/> }
                    </Button>
                  </InputAdornment>
                )
              }}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.submitButton}
            >
              Potwierdź
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="button"
              disabled={loading}
              className={classes.submitButton}
              onClick={() => push('/login')}
            >
              Anuluj
            </Button>
          </form>
        </FormProvider>
      </>
    </AuthPageCard>
  )
}

export default ResetPasswordPage
