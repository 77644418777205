import {Box, Container, Typography} from '@material-ui/core'
import { FC } from 'react'
import { useExamPageCardStyles } from './ExamPageCard.styles'
import clsx from "clsx";

interface ExamPageCardProps {
	fullName: string;
  exam?: boolean
}

const ExamPageCard: FC<ExamPageCardProps> = (
  {
    fullName,
    exam,
    children,
  }) => {
	const classes = useExamPageCardStyles()

	return (
    <Container 
			maxWidth="lg" 
			className={clsx(classes.root, "exam-page-card")}
		>
      <Box>
        {exam && (
          <Typography variant="h2" component='p'>
            Ewaluacja ICF
          </Typography>
        )}

      	<Typography variant="h4" component='p' className={"print-user-title"}>
					{fullName}
        </Typography>
      </Box>

			<Box>
      	{children}
			</Box>
    </Container>
  )
}

export default ExamPageCard