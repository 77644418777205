import {createStyles, makeStyles, Theme} from "@material-ui/core";

export const useMenuItemsStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    color: theme.palette.common.white
  },
  activeItem: {
    backgroundColor: theme.palette.secondary.light
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  }
}))