import React, {useState} from 'react'
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component";
import TestedPersonsList from "../components/testedPersons/testedPersonsList/TestedPersonsList.component";
import {useSelector} from "react-redux";
import {selectUserRole} from "../store/user/user.selectors";
import {UserRole} from "../components/user/userRole/UserRole.types";

const TestedPersonsPage = () => {
  const currentUserRole = useSelector(selectUserRole)
  const [isOpenAddPersonPopup, setIsOpenAddPersonPopup] = useState<boolean>(false)

  return (
    <AdminPageCard
      title="Lista osób badanych"
      actionButtonText={currentUserRole === UserRole.GUARDIAN
        ? "Dodaj osobę badaną"
        : undefined
      }
      actionButtonProps={{
        onClick: () => setIsOpenAddPersonPopup(true)
      }}
    >
      <TestedPersonsList
        isOpenAddUserPopup={isOpenAddPersonPopup}
        onCloseAddUserPopup={() => setIsOpenAddPersonPopup(false)}
      />
    </AdminPageCard>
  )
}

export default TestedPersonsPage