import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useAuthPageStyles = makeStyles((theme: Theme) => createStyles({
  pageBox: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  contentWrapper: {
    position: "relative",
    minHeight: "440px",
    width: "560px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 6),
    boxShadow: theme.shadows[8],
    height: "fit-content",
    marginBottom: theme.spacing(14),
  },
  goBackButton: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  goBackButtonIcon: {
    ...theme.typography.body1,
    marginRight: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: "100%",
    padding: theme.spacing(1.5, 0)
  }
}))
