import React, {FC} from "react"
import {Box} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useForm, FormProvider } from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import { ADD_TENANT } from "./AddTenant.mutation"
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup"
import { addTenantValidationSchema } from "./AddTenant.validation"

interface AddTenantPopupProps {
  open: boolean;
  refetch(): void;
  onClose(): void;
}

const AddTenantPopup: FC<AddTenantPopupProps> = ({open, refetch, onClose}) => {
  const [createTenant, {loading}] = useMutation(ADD_TENANT)

  const form = useForm({
    mode: "all",
    resolver: yupResolver(addTenantValidationSchema),
    defaultValues: {
      name: "",
    }
  })

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await createTenant({
        variables: {
          input: {
            tenant: values
          }
        }
      })

      await refetch()
      handleClose()
    } catch (exception) {
      // processResponseException(form, exception);
    }
  })

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title="Dodaj podmiot"
      actionButtonText={"Dodaj"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <TextFieldController
              name="name"
              label={'Nazwa'}
              isRequired
              trimStart
              disabled={loading}
            />
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default AddTenantPopup
