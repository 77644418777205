import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'

export const useExamResultsTableStyles = makeStyles((theme:Theme) => createStyles({
  title: {
    display: "block",
    margin: theme.spacing(0,0, 2),
  },
  fieldName: {
    ...theme.typography.body2,
    fontWeight: "bold",
  },
  circleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  red: {
    backgroundColor: "#c0392b",
  },
  yellow: {
    backgroundColor: "#f1c40f",
  },
  green: {
    backgroundColor: "#2ecc71",
  },
  marginRight: {
    marginRight: theme.spacing(1),
  }
}))