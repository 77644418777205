import React, {FC, useEffect, useState} from 'react'
import TextFieldController, {
  TextFieldControllerProps
} from "../../formItems/textFieldController/TextFieldController.component";
import {Box, MenuItem, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {OccupationSelectItem} from "./TestedPersonOccupationSelect.types";
import {OCCUPATION_LIST_QUERY} from "./TestedPersonOccupationSelect.query";

interface TestedPersonOccupationSelectProps
  extends TextFieldControllerProps
{}

const TestedPersonOccupationSelect: FC<TestedPersonOccupationSelectProps> = ({disabled,...props}) => {
  const {data, loading} = useQuery<{connection: {edges: Array<{ node: OccupationSelectItem}>}}>(OCCUPATION_LIST_QUERY);
  const [options, setOptions] = useState<OccupationSelectItem[]>([])

  useEffect(() => {
    if(data) {
      setOptions(data.connection.edges.map(edge => edge.node))
    }

  },[loading])

  return(
    <TextFieldController
      select
      {...props}
      disabled={disabled || loading}
    >
      {options.length ? (
        options
          .sort((a,b) => a.name.localeCompare(b.name))
          .map((option: OccupationSelectItem) => (
          <MenuItem
            key={option.occupationTypeId}
            value={option.occupationTypeId}
          >
            {option.name}
          </MenuItem>
        ))
      ) : (
        <Box m={2}>
          <Typography variant={"body2"}>
            <i>Brak Zawodów</i>
          </Typography>
        </Box>
      )}

    </TextFieldController>
  )
}

export default  TestedPersonOccupationSelect