import {Occupations} from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.types";
import {ExamResultTypes} from "../../testedPersons/examsResults/ExamsResult.types";

export type AnswersType = {
  value: number,
  label: string,
  description: string,
}

export type AdvisoryActivitiesType = {
  code: string,
  books: BookType[]
}

export type BookType = {
  title: string,
  author: string,
  link?: string,
  resultType?: ExamResultTypes[]
}

export type AnswersList = {
  answersList: AnswerListItem[],
  user: {userId: string, fullName: string, finishedExamAt: Date}
}

export type AnswerListItem = {
  category: string,
  answers: string[]
  additionalInformation: string | null;
}

export type DetailedQuestion = {
  question: string,
  scale?: number,
  code: string,
  answers: AnswersType[]
}

export type QuestionType = {
  id:number,
  code: string,
  title: string,
  question: string,
  crucialForOccupations: Occupations[],
  answers: AnswersType[],
  detailedQuestions?: {
    questions?: DetailedQuestion[],
    breakpoint?: {
      header: string,
      questions: DetailedQuestion[]
    }
  }
}

export enum ColorLight {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green"
}