import React, {FC, useEffect} from "react"
import {Box, Chip, TableBody, TableHead, Typography} from "@material-ui/core"
import {USERS_LIST_QUERY} from "./UsersList.query"
import useRelayConnection from "../../../hooks/useRelayConnection"
import {UserItemNode} from "../../../store/user/user.types"
import {usePaginationParams} from "../../../hooks/useQueryParams"
import TableWithPagination from "../../common/tableWithPagination/TableWithPagination.component"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import UsersListActions from "../usersListActions/UsersListActions.component"
import {useUsersListStyles} from "./UsersList.styles"
import AddUserPopup from "../addUserPopup/AddUser.component"
import {getUserRoleChipColor, getUserRoleLabel} from "../userRole/UserRole.utils"
import {UserRole} from "../userRole/UserRole.types";
import TableCellWithFilter from "../../common/tableCellWithFilter/TableCellWithFilter.component"
import {StringParam, useQueryParam} from "use-query-params"
import {FilterSelectType} from "../../common/tableCellWithFilter/TableCellWithFilter.types"

interface UsersListProps {
  isOpenAddUserPopup: boolean;
  onCloseAddUserPopup(): void;
}

const UsersList: FC<UsersListProps> = ({isOpenAddUserPopup, onCloseAddUserPopup}) => {
  const classes = useUsersListStyles()

  const [fullNameFilter, setFullNameFilter] = useQueryParam("fullName", StringParam)
  const [tenantIdFilter, setTenantIdFilter] = useQueryParam("tenantId", StringParam)

  const filters = {
    userRole: {notEqualTo: UserRole.EXAMINED_PERSON},
    archived: {equalTo: false},
    fullName: fullNameFilter ? {likeInsensitive: fullNameFilter} : undefined,
    tenantsIds: tenantIdFilter ? {anyEqualTo: tenantIdFilter}: undefined
  }

  const {tableProps, refetch, loading} = useRelayConnection<UserItemNode>(USERS_LIST_QUERY, {
    variables: {
      filter: filters,
      orderBy: ['REGISTERED_AT_DESC']
    },
    pagination: usePaginationParams()
  })

  useEffect(() => {
    refetch()
  }, [fullNameFilter])

  return (
    <Box>
      <AddUserPopup
        open={isOpenAddUserPopup}
        refetch={refetch}
        onClose={onCloseAddUserPopup}
      />

      <TableWithPagination {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCellWithFilter
              defaultValue={fullNameFilter}
              label="Imię i nazwisko"
              disabled={loading}
              onFilterSubmit={(val) => setFullNameFilter(val)}
            >
              <Typography className={classes.fieldName}>Imię i nazwisko</Typography>
            </TableCellWithFilter>
            <TableCell>
              <Typography className={classes.fieldName}>E-mail</Typography>
            </TableCell>

            <TableCell>
              <Typography className={classes.fieldName}>Rola</Typography>
            </TableCell>

            <TableCellWithFilter
              defaultValue={tenantIdFilter}
              label="Podmiot"
              disabled={loading}
              select={FilterSelectType.TENANTS}
              onFilterSubmit={(val) => setTenantIdFilter(val)}
            >
              <Typography className={classes.fieldName}>Podmiot</Typography>
            </TableCellWithFilter>

            <TableCell>
              <Typography className={classes.fieldName} align={"right"}>Akcje</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            { tableProps.dataSource.map((dataItem: UserItemNode) => (
              <TableRow key={dataItem.email}>
                <TableCell>
                  {dataItem.name} {dataItem.surname}
                </TableCell>

                <TableCell>
                  {dataItem.email}
                </TableCell>

                <TableCell>
                  <Chip
                    label={getUserRoleLabel(dataItem.userRole)}
                    color={getUserRoleChipColor(dataItem.userRole)}
                  />
                </TableCell>

                <TableCell>
                  {dataItem.tenantsList && dataItem.tenantsList.map(tenant => (
                    <Chip
                      key={tenant.tenantId}
                      label={tenant.name}
                      className={classes.chip}
                    />
                  ))}
                </TableCell>

                <TableCell align={"right"}>
                  <UsersListActions
                    user={dataItem}
                    refetch={refetch}
                  />
                </TableCell>

              </TableRow>
            ))}
          </>
        </TableBody>
      </TableWithPagination>
    </Box>
  )
}

export default UsersList
