import {combineReducers} from "@reduxjs/toolkit"
import userReducer from "./user/user.slice"
import sessionReducer from "./session/session.slice"
import settingsReducer from "./settings/settings.slice"
import savingExamAnswerReducer from './savingExamAnswer/savingExamAnswer.slice'
import {connectRouter} from "connected-react-router"
import {History} from "history"

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  session: sessionReducer,
  settings: settingsReducer,
  savingExamAnswer: savingExamAnswerReducer
})

export default createRootReducer
