import { gql } from "@apollo/client"

export const USER_SELECT_LIST_QUERY = gql`
    query GetUsers(
      $first: Int,
      $offset: Int,
      $filter: UserFilter,
      $orderBy: [UsersOrderBy!]
    ) {
      connection: users(first: $first, offset: $offset, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            userId
            name
            surname
          }
        }
      }
    }
`