import React, { FC, useState } from "react"
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component"
import { Box } from "@material-ui/core"
import UsersList from "../components/user/usersList/UsersList.component"

const UsersPage: FC = () => {
  const [isOpenAddUserPopup, setIsOpenAddUserPopup] = useState<boolean>(false)

  return (
    <AdminPageCard
      title="Lista użytkowników"
      actionButtonText={"Dodaj użytkownika"}
      actionButtonProps={{
        onClick: () => setIsOpenAddUserPopup(true)
      }}
    >
      <Box>
        <UsersList
          isOpenAddUserPopup={isOpenAddUserPopup}
          onCloseAddUserPopup={() => setIsOpenAddUserPopup(false)}
        />
      </Box>
    </AdminPageCard>
  )
}

export default UsersPage
