import React, { FC, useState } from "react"
import { useMutation } from "@apollo/client";
import { Box, Button, InputAdornment } from "@material-ui/core"
import TextFieldController from "../../../components/formItems/textFieldController/TextFieldController.component"
import {RouteComponentProps, useHistory, useParams} from "react-router"
import { useForm, FormProvider } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useLoginPageStyles } from "./LoginPage.styles"
import { RoutePath } from "../../../routes/Routes.types"
import AuthPageCard from "../../../components/authPageCard/AuthPageCard.component"
import { clearSession, initSession } from "../../../store/session/session.slice"
import { setSettings } from "../../../store/settings/settings.slice"
import { yupResolver } from "@hookform/resolvers/yup"
import { loginValidationSchema } from "./LoginPage.validation"
import GlobalFormErrorMessage
  from "../../../components/formItems/errors/globalFormErrorMessage/GlobalFormErrorMessage.component"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { AUTHENTICATE } from "./LoginPage.mutation"
import { TokenData } from "../../../store/session/session.types"
import jwtDecode from "jwt-decode"

interface LoginPageParams {
  ref?: string;
}

const LoginPage: FC<RouteComponentProps<LoginPageParams>> = () => {
  const {ref} = useParams<LoginPageParams>()
  const {push} = useHistory()
  const dispatch = useDispatch()
  const classes = useLoginPageStyles()

  const [error, setError] = useState<string>()
  const [login, {loading}] = useMutation(AUTHENTICATE)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  dispatch(setSettings({logInRedirectUrl: ref}))

  const form = useForm({
    mode: "all",
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      password: "",
      email: ""
    }
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    dispatch(clearSession())

    try {
      const { data: { session: { sessionToken }} } = await login({
        variables: {
          input: values
        }
      })

      if (!sessionToken) {
        setError("Błędne dane logowania")
      } else {
        const decodedToken: TokenData = jwtDecode(sessionToken)
        dispatch(initSession({
          sessionState: {
            sessionToken,
            exp: decodedToken.exp,
            user_id: decodedToken.user_id,
          }
        }))
      }
    } catch (e) {
      console.error(e)
    }
  })

  const goToForgetPasswordPage = () => {
    push(RoutePath.FORGOT_PASSWORD)
  }

  return (
    <AuthPageCard title="Zaloguj się">
      <>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            { error &&
              <GlobalFormErrorMessage message={error}/>
            }

            <TextFieldController
              name="email"
              label={'E-mail'}
              isRequired
              disabled={loading}
              className={classes.input}
            />

            <TextFieldController
              name="password"
              label={'Hasło'}
              isRequired
              trimStart
              disabled={loading}
              className={classes.input}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={loading}
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                    >
                      { showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/> }
                    </Button>
                  </InputAdornment>
                )
              }}
            />

            <Box className={classes.forgetPasswortButtonWrapper}>
              <Button
                className={classes.forgetPasswortButton}
                disabled={loading}
                onClick={goToForgetPasswordPage}
              >
                Nie pamiętasz hasła?
              </Button>
            </Box>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.submitButton}
            >
              Zaloguj się
            </Button>
          </form>
        </FormProvider>
      </>
    </AuthPageCard>
  )
}

export default LoginPage
