import React, {FC} from "react"
import {Container, Box,} from "@material-ui/core"
import BaseLayout from "./BaseLayout"
import UserMenu from "../components/menu/userMenu/UserMenu.component"
import SessionManager from "../components/common/sessionManager/SessionManager.component"

const TestedPersonLayout: FC = ({ children }) => {

  return (
    <BaseLayout>
      <Box>
        <Box>
          <SessionManager/>

          <UserMenu/>
        </Box>

        <Box display="flex" flex="1">
          <Container
          >
            <Box height={"100%"}>
              { children }
            </Box>
          </Container>
        </Box>
      </Box>
    </BaseLayout>
  )
}

export default TestedPersonLayout
