import React, { FC } from "react"
import {Box} from "@material-ui/core"
import { usePageFooterStyles } from "./PageFooter.styles"

interface PageFooterProps {}

const PageFooter: FC<PageFooterProps> = () => {
  const classes = usePageFooterStyles()

  return (
    <Box className={classes.root}>
      <div>
        v { process.env.REACT_APP_VERSION }
      </div>

      <div>
        © 2022 Copyright
      </div>
    </Box>
  )
}

export default PageFooter
