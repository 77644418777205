import React, { FC } from "react"
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core"
import { useReportsStyles } from "./Reports.styles"
import useFileDownload from "../../hooks/useFileDownload"
import DownloadingButtonIcon from "../common/downlodingButtonIcon/DownloadingButtonIcon.component"
import { GetApp } from '@material-ui/icons'
import getLocalISOTime from "../../utils/getLocalISOTime"

interface ReportsProps {}

const Reports: FC<ReportsProps> = () => {
  const classes = useReportsStyles()

  const { download, downloading } = useFileDownload('/api/report')

  const onGenerateReportButtonClick = () => {
    const reportName = `Raport ${getLocalISOTime()}.xlsx`
    download(reportName)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <Box className={classes.card}>
          <Typography className={classes.title}>
            Raport użytkowników
          </Typography>

          <Divider/>

          <Box mt={3} mb={4}>
            <Typography>
              Raport ilościowy użytkowników
            </Typography>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color={"primary"}
              startIcon={downloading ? <DownloadingButtonIcon/> : <GetApp/>}
              size={"large"}
              onClick={onGenerateReportButtonClick}
            >
              Pobierz
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Reports
