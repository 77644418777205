import React, {FC} from 'react'
import {LinearProgress, Typography} from "@material-ui/core";
import {useExamProgressBarStyles} from "./ExamProgressBar.styles";

interface ExamProgressBarProps {
  currentSectionNumber: number;
  sectionsAmount: number;
}

const ExamProgressBar: FC<ExamProgressBarProps> = ({currentSectionNumber, sectionsAmount}) => {
  const examProgress = currentSectionNumber * 100 / sectionsAmount
  const classes = useExamProgressBarStyles()

  return (
    <div className={"print-hidden"}>
      <LinearProgress
        value={examProgress}
        variant={"determinate"}
        className={classes.progressBar}
      />

      <Typography align={"right"}>
        Strona { currentSectionNumber } z { sectionsAmount }
      </Typography>
    </div>
  )
}

export default ExamProgressBar