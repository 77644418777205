export const typography = {
  fontFamily: [
    "Arial",
    "sans-serif",
  ].join(),
  h1: {
    fontWeight: 300,
    fontSize: "48px",
    lineHeight: "56px",
    letterSpacing: "0.02em",
  },
  h2: {
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: "0.03em"
  },
  h3: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0.03em"
  },
  h4: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  h5: { //h1-mobile
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body1: { // renders by default as p tag
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body2: { // renders by default as p tag
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  subtitle1: { // renders by default as p tag
    fontSize: "13px",
    lineHeight: "24px",
    letterSpacing: "0.04em",
  },
  subtitle2: { // renders by default as p tag
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
  },
  button: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: undefined,
  },
}
