import React, { FC } from "react"
import { Box, TableBody, TableHead, Typography } from "@material-ui/core"
import { TENANTS_LIST_QUERY} from "./TenantsList.query";
import useRelayConnection from "../../../hooks/useRelayConnection"
import { TenantItemNode } from "./TenantsList.types"
import { usePaginationParams } from "../../../hooks/useQueryParams"
import TableWithPagination from "../../common/tableWithPagination/TableWithPagination.component"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TenantsListActions from "../tenantsListActions/TenantsListActions.component"
import getDateStringFromIsoFormat from "../../../utils/getDateStringFromIsoFormat"
import { useTenantsListStyles } from "./TenantsList.styles"
import AddTenantPopup from "../addTenantPopup/AddTenant.component"
import LoaderBox from "../../common/loaderBox/LoaderBox.component";

interface TenantsListProps {
  isOpenAddTenantPopup: boolean;
  onCloseAddTenantPopup(): void;
}

const TenantsList: FC<TenantsListProps> = ({isOpenAddTenantPopup, onCloseAddTenantPopup}) => {
  const classes = useTenantsListStyles()

  const {tableProps, refetch, loading} = useRelayConnection<TenantItemNode>(TENANTS_LIST_QUERY,{
       variables: {
         orderBy: ['NATURAL'],
         filter: {
           archived: {equalTo: false}
         }
       },
       pagination: usePaginationParams()
     })

  if(loading) return <LoaderBox />

  return (
    <Box>
      <AddTenantPopup
        open={isOpenAddTenantPopup}
        refetch={refetch}
        onClose={onCloseAddTenantPopup}
      />

      <TableWithPagination {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className={classes.fieldName}>Nazwa podmiotu</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.fieldName}>Data utworzenia</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.fieldName}>Liczba opiekunów</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.fieldName}>Liczba osób badanych</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className={classes.fieldName}>Akcje</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            { tableProps.dataSource.map((dataItem: TenantItemNode) => (
              <TableRow key={dataItem.name}>
                <TableCell>
                  {dataItem.name}
                </TableCell>

                <TableCell>
                  {getDateStringFromIsoFormat(dataItem.createdAt)}
                </TableCell>

                <TableCell>
                  {dataItem.gurdiansCount}
                </TableCell>

                <TableCell>
                  {dataItem.examinedPersonsCount}
                </TableCell>

                <TableCell align="right">
                  <TenantsListActions
                    tenant={dataItem}
                    refetch={refetch}
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        </TableBody>
      </TableWithPagination>
    </Box>
  )
}

export default TenantsList
