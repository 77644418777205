import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useUsersListStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldName: {
      ...theme.typography.body2,
      fontWeight: "bold",
    },
    chip: {
      marginRight: theme.spacing(1),
    },
    occupationType: {
      padding: theme.spacing(1),
      background: theme.palette.secondary.contrastText,
      borderRadius: theme.spacing(2),
      display: "inline-block",
    },
    occupationTypeText: {
      ...theme.typography.subtitle1,
      lineHeight: "1.1rem",
    }
  }),
)