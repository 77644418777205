import {makeStyles, createStyles} from "@material-ui/core";

export const useTestedPersonsWorkplaceThreatsSelectStyles = makeStyles(() => createStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: "2px",
  },
}))