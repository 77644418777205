import React, { FC, useState } from "react"
import {Button, ButtonGroup, Tooltip} from "@material-ui/core"
import { useTenantsListActionsStyles } from "./TenantsListActions.styles"
import EditIcon from '@material-ui/icons/Edit'
import ArchiveIcon from '@material-ui/icons/Archive'
import EditTenantPopup from "../editTenantPopup/EditTenant.component"
import { TenantItemNode } from "../tenantsList/TenantsList.types"
import PreviewTenantPopup from "../previewTenantPopup/PreviewTenant.component"
import ArchiveTenantPopup from "../archiveTenantPopup/ArchiveTenant.component"

interface TenantsListActionsProps {
  tenant: TenantItemNode;
  refetch(): void;
}

const TenantsListActions: FC<TenantsListActionsProps> = ({ tenant, refetch }) => {
  const classes = useTenantsListActionsStyles()

  const [isOpenEditTenantPopup, setIsOpenEditTenantPopup] = useState<boolean>(false)
  const [isOpenPreviewTenantPopup, setIsOpenPreviewTenantPopup] = useState<boolean>(false)
  const [isOpenArchiveTenantPopup, setIsOpenArchiveTenantPopup] = useState<boolean>(false)

  return (
    <>
      <ButtonGroup >
        <Tooltip title={'Edytuj'}>
          <Button
            size={"small"}

            className={classes.button}
            onClick={() => setIsOpenEditTenantPopup(true)}
          >
            <EditIcon className={classes.icon}/>
          </Button>
        </Tooltip>

        <Tooltip title={'Archiwizuj'}>
          <Button
            size={"small"}
            color={"secondary"}
            className={classes.button}
            onClick={() => setIsOpenArchiveTenantPopup(true)}
          >
            <ArchiveIcon className={classes.icon}/>
          </Button>
        </Tooltip>

        <EditTenantPopup
          tenantId={tenant.tenantId}
          name={tenant.name}
          open={isOpenEditTenantPopup}
          refetch={refetch}
          onClose={() => setIsOpenEditTenantPopup(false)}
        />

        <PreviewTenantPopup
          tenant={tenant}
          open={isOpenPreviewTenantPopup}
          onClose={() => setIsOpenPreviewTenantPopup(false)}
        />

        <ArchiveTenantPopup
          tenantId={tenant.tenantId}
          name={tenant.name}
          open={isOpenArchiveTenantPopup}
          refetch={refetch}
          onClose={() => setIsOpenArchiveTenantPopup(false)}
        />
      </ButtonGroup>
    </>
  )
}

export default TenantsListActions
