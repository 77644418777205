import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useExamPageCardStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(4, "auto"),
        padding: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
}))