import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles";

export const useExamQuestionCardStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(4,0)
  },
  question: {
    margin: theme.spacing(2,0, 1)
  },
  divider: {
    marginTop: theme.spacing(6)
  },
  detailedQuestionsBox: {
    marginTop: theme.spacing(4)
  },
  header: {
    margin: theme.spacing(4,0,0)
  },
  disabledForm : {
    pointerEvents: "none",
    opacity: 0.7
  },
  loaderBoxWrapper: {
    height: "30px",
    marginTop: theme.spacing(-1),
  },
  loaderBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: theme.spacing(0, 2),
    color: theme.palette.info.dark,
  },
  loaderText: {
    marginLeft: theme.spacing(1),
  },
  nextPageButton: {
    marginLeft: theme.spacing(2)
  }
}))