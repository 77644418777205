import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Reducer} from "redux";

const initialState: string[] = []

export const savingExamAnswerSlice = createSlice({
  name: "savingExamAnswer",
  initialState,
  reducers: {
    addSavingAnswer: (state: string[], action: PayloadAction<Partial<string>>) => {
      return [...state, action.payload]
    },
    removeSavingAnswer: (state: string[], action: PayloadAction<Partial<string>>) => {
      return state.filter(item => item !== action.payload)
    }
  }
})

export const {
  addSavingAnswer,
  removeSavingAnswer
} = savingExamAnswerSlice.actions

export default savingExamAnswerSlice.reducer as Reducer<typeof initialState>