import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

interface PopupStylesProps {
  desktopWidth?: string;
}

export const usePopupStyles = makeStyles((theme: Theme) => createStyles({
  dialogBox: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "400px",
      width: (props: PopupStylesProps) => props.desktopWidth || "540px",
      maxWidth: (props: PopupStylesProps) => props.desktopWidth || "600px",
    }
  },
  dialogTitle: {
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  dialogActions: {
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      "&$dialogActionsSpacing > *": {
        width: "100%",
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      }
    },
  },
  dialogActionsSpacing: {
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(2),
  }
}))
