import React, {FC, useEffect, useState} from 'react'
import {useTableCellWithFilter} from "./TableCellWithFilter.styles"
import {Box, Button, IconButton, InputAdornment, Popover, TableCell} from "@material-ui/core"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import {FormProvider, useForm} from "react-hook-form"
import {Close} from "@material-ui/icons"
import {ReactComponent as FilterOnIcon} from "../../../assets/icons/filterOnIcon.svg"
import {ReactComponent as FilterOffIcon} from "../../../assets/icons/filterOffIcon.svg"
import {FilterSelectType} from "./TableCellWithFilter.types"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component"
import TestedPersonOccupationSelect
  from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.component";
import UserSelect from "../../user/userSelect/UserSelect.component";

export interface TableCellWithFilterProps {
  defaultValue?: string | null;
  label: string;
  disabled: boolean;
  select?: FilterSelectType;
  onFilterSubmit(val: string | undefined): void;
}

const TableCellWithFilter: FC<TableCellWithFilterProps> = (
  {
    defaultValue,
    label,
    onFilterSubmit,
    select,
    disabled,
    children,
  }
) => {
  const classes = useTableCellWithFilter()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopover = () => setAnchorEl(null)

  const form = useForm()

  useEffect(() => {
    if (form) {
      if (defaultValue) {
        form.setValue("value", defaultValue.replace(/%/g,''))
        setIsFilterActive(true)
      }
    }
  }, [form, defaultValue])

  const handleSubmit = form.handleSubmit(async (values) => {
    const phrase = values["value"]

    onFilterSubmit(
      select
        ? phrase
        : phrase ? `%${phrase.replace(/%/g,'%')}%` : ''
    )

    setIsFilterActive(!!phrase)
    closePopover()
  })

  const clear = () => {
    form.setValue("value", "")
    onFilterSubmit(undefined)
    setIsFilterActive(false)
    closePopover()
  }

  const inputProps = {
    name: "value",
    label,
    mb: true,
    autoFocus: !select,
    SelectProps: {
      multiple: false,
      IconComponent: () => null
    },
    multiple: false,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            disabled={disabled}
            title={"Wyczyść"}
            type={"button"}
            onClick={clear}
          >
            <Close/>
          </IconButton>
        </InputAdornment>
      )
    }
  }

  const filterPopover = (
    <Box className={classes.popover}>
      { (() => {
        switch (select) {
          case FilterSelectType.TENANTS: return <TenantsSelect  {...inputProps} />
          case FilterSelectType.GUARDIANS: return <UserSelect {...inputProps} />
          case FilterSelectType.TESTED_PERSON_OCCUPATION: return <TestedPersonOccupationSelect {...inputProps} />
          default: return <TextFieldController {...inputProps} />
        }
      })() }

      <Box className={classes.buttonsWrapper}>
        <Button type="submit" variant={"contained"} color={"primary"}>
          Szukaj
        </Button>
      </Box>
    </Box>
  )

  return (
    <TableCell>
      <Box className={classes.wrapper}>
        { children }

        <Box ml={1}>
          <IconButton title={"Filtruj"} onClick={handleClick}>
            { isFilterActive
              ? <FilterOnIcon/>
              : <FilterOffIcon/>
            }
          </IconButton>
        </Box>
      </Box>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            { filterPopover }
          </form>
        </FormProvider>
      </Popover>
    </TableCell>
  )
}

export default TableCellWithFilter