import * as yup from "yup"
import { UserRole } from "../userRole/UserRole.types"
import { emailErrorMessage, requiredErrorMessage, } from "../../formItems/errors/yupErrorMessages"

export const addUserValidationSchema = yup.object().shape({
  name: yup.string().required(requiredErrorMessage),
  surname: yup.string().required(requiredErrorMessage),
  email: yup.string()
    .required(requiredErrorMessage)
    .email(emailErrorMessage),
  occupationTypeId: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
  userRole: yup.mixed()
    .required(requiredErrorMessage)
    .oneOf(Object.values(UserRole), requiredErrorMessage),
  tenants: yup.mixed()
    .when("userRole", {
      is: UserRole.GUARDIAN,
      then: yup.array().min(1, requiredErrorMessage)
    })
    .when({
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage),
    }),
  workplaceThreats: yup.array()
    .min(1, requiredErrorMessage),
  birthYear: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string()
        .required(requiredErrorMessage)
        .matches(/^\d+$/, "Wartość pola powinno być liczbą całkowitą")
        .test("min", "Minimalna wartość wynosi 1900", (val) => Number(val) >= 1900)
        .test("max", `Maksymalna wartość to ${new Date().getFullYear()}`, (val) => Number(val) <= new Date().getFullYear())
    }),
  age: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string()
        .required(requiredErrorMessage)
        .matches(/^\d{2}$/, "Wartość pola powinno być liczbą całkowitą zawierającą dwie cyfry")
    }),
  ageCategoryId: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
  genderId: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
  motivation: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
  seniorityId: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
  educationId: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
  weigh: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string()
        .required(requiredErrorMessage)
        .matches(/^\d+$/, "Wartość pola powinno być liczbą całkowitą")
    }),
  height: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string()
        .required(requiredErrorMessage)
        .matches(/^\d+$/, "Wartość pola powinno być liczbą całkowitą")
    }),
  bmi: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string()
        .required(requiredErrorMessage)
        .matches(/^[0-9.,]+$/, "Wartość pola powinno być liczbą")
        .matches(/^\d+[.,]?\d?$/, "Wartość może mieć maksymalnie jedną cyfrę po przecinku")
    }),
  workTypeId: yup.mixed()
    .when("userRole", {
      is: UserRole.EXAMINED_PERSON,
      then: yup.string().required(requiredErrorMessage)
    }),
})
