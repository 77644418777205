import React, {FC, MouseEvent} from "react"
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle, IconButton, useMediaQuery, useTheme,
} from "@material-ui/core"
import {usePopupStyles} from "./Popup.styles"
import CloseIcon from '@material-ui/icons/Close'

enum CancelModalReason {
  ESCAPE_KEY_DOWN = "escapeKeyDown",
  BACKDROP_CLICK = "backdropClick",
}

interface PopupProps extends DialogProps {
  open: boolean;
  onClose?(): void;
  title: string;
  disableBackdropClick?: boolean;
  actionButtonText?: string;
  actionButtonProps?: ButtonProps;
  closeButtonText?: string;
  closeButtonProps?: ButtonProps;
  disabled?: boolean;
  desktopWidth?: string;
}

const Popup: FC<PopupProps> = (
  {
    open,
    onClose,
    title,
    disableBackdropClick = false,
    actionButtonText,
    actionButtonProps,
    closeButtonText,
    closeButtonProps,
    disabled = false,
    desktopWidth,
    children,
    ...props
  }
) => {
  const classes = usePopupStyles({desktopWidth})

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  const onDialogClose = () => (event: MouseEvent, reason: CancelModalReason) => {
    if (disableBackdropClick && reason === CancelModalReason.BACKDROP_CLICK) {
      return
    }
    if(onClose){
      onClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      fullScreen={isSmUp ? props.fullScreen : true}
      {...props}
      classes={{
        paper: classes.dialogBox,
        ...props.classes
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitle,
        }}
      >
        { title }

        { onClose &&
          <IconButton
            aria-label="Zamknij"
            className={classes.closeButton}
            disabled={disabled}
            onClick={onClose}
          >
            <CloseIcon/>
          </IconButton>}
      </DialogTitle>

      <DialogContent dividers>
        { children }
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.dialogActions,
          spacing: classes.dialogActionsSpacing
        }}
      >
        { onClose &&
            <Button
              variant="outlined"
              color={"primary"}
              onClick={onClose}
              disabled={disabled}
              {...closeButtonProps}
            >
              {closeButtonText || "Zamknij"}
            </Button>
        }

        { (actionButtonText && actionButtonProps) &&
          <Button
            variant="contained"
            color={"primary"}
            disabled={disabled}
            {...actionButtonProps}
          >
            { actionButtonText }
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default Popup
