import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";

export const useButtonsControllerStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  label: {
    margin: theme.spacing(-2,2,0),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    width: "130px",
    fontWeight: 800,
  },
  errorContainer: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  errorText: {
    color: theme.palette.error.main
  },
  button: {
    minWidth: "95px",
    position: "relative",
    padding: theme.spacing(1),
    fontSize: "12px",
    lineHeight: "18px",
    "&.Mui-disabled": {
      pointerEvents: "none",
      opacity: "0.6",
      color: theme.palette.text.disabled,
    }
  },
  selectedAnswerButton: {
    pointerEvents: "none",
  },
  suggestedAnswerButton:{
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.common.white
  },
  suggestedAnswerText: {
    position: "absolute",
    color: theme.palette.success.dark,
    top: "70px",
    '@media (min-width: 900px)':{
      top: "55px"
    }
  }
}))