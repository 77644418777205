import { createTheme } from "@material-ui/core"
import { plPL } from '@material-ui/core/locale';
import { palette } from "./palette"
import { typography } from "./typography"
import { mixins } from "./mixins"
import { breakpoints } from "./breakpoints"
import { props } from "./props/props"

const theme = createTheme({
  palette,
  mixins,
  typography,
  shape: {
    borderRadius: 8,
  },
  breakpoints,
  props: props,
}, plPL)

export default theme
