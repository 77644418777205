export interface OccupationSelectItem {
  name: string;
  occupationTypeId: string
}

export enum Occupations {
  INDUSTRIAL_WORKERS_AND_CRAFTSMEN = "0eeb241f-8e51-4c92-874c-63934f9b582d",
  OFFICE_WORKERS = "241d2af5-a3f9-431e-bb87-450cf7bf8863",
  REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS = "33f5fcc1-3d81-4e7b-86c3-c53e8b16cf2a",
  SERVICE_EMPLOYEES_AND_SELLERS = "4d10f835-3859-432a-990e-05ce11798311",
  FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN = "56cd09ac-981c-457c-811c-265313305de3",
  SPECIALISTS = "69392918-06b0-470b-be74-3bb9ade400a8",
  TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS = "6b8238bd-5487-43af-a1c2-87274c321a95",
  PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS = "e4ca2b5b-e532-433e-8595-bb7028e36369",
  WORKERS_DOING_SIMPLE_JOBS = "e5c5dd53-1545-4c92-aa32-8b8bb8e27eeb"
}